'use client'
import { CookieConsentConfig } from 'vanilla-cookieconsent'

const toggleConsent = (analyticsEnabled: boolean, adEnabled: boolean) => {
    window.gtag(
        'consent',
        'update',
        {
            [window.CONSENT_MODE.ANALYTICS]: analyticsEnabled ? 'granted' : 'denied',
            [window.CONSENT_MODE.ADS]: adEnabled ? 'granted' : 'denied',
            [window.CONSENT_MODE.AD_USER]: adEnabled ? 'granted' : 'denied',
            [window.CONSENT_MODE.AD_PERSO]: adEnabled ? 'granted' : 'denied',
        },
    )

    if (adEnabled) {
        window.adsbygoogle.requestNonPersonalizedAds = 0
    }
}

const sendBlockedEvents = () => {
    window.consentBlockedEvents.forEach((blockedEvent: any) => window.gtag(...blockedEvent))
    window.consentBlockedEvents = []
}

const COOKIE_CATEGORY_ANALYTICS =  'analytics'
const COOKIE_CATEGORY_ADS =  'ads'
const COOKIE_CATEGORY_NECESSARY =  'necessary'

const cookieRunData: CookieConsentConfig = {
    autoShow: false,
    disablePageInteraction: true,
    hideFromBots: true,
    cookie: {
        name: 'rm_cookie_consent',
        // domain: process.env.NEXT_PUBLIC_RM_NEXT_URL?.replace('http://', ''),
        domain: 'reassurez-moi.fr',
        path: '/',
    },
    categories: {
        [COOKIE_CATEGORY_NECESSARY]: {
            enabled: true,
            readOnly: true,
        },
        [COOKIE_CATEGORY_ANALYTICS]: {
            enabled: true,
        },
        [COOKIE_CATEGORY_ADS]: {
            enabled: true,
        },
    },
    onConsent: (granted) => {
        setTimeout(() => {
            const analyticsEnabled = granted.cookie.categories.includes(COOKIE_CATEGORY_ANALYTICS)
            const adEnabled = granted.cookie.categories.includes(COOKIE_CATEGORY_ADS)

            if (analyticsEnabled) {
                window.gtag('event', 'cookie_consent_analytics_granted')
                setTimeout(sendBlockedEvents, 500)
            }

            toggleConsent(analyticsEnabled, adEnabled)
        }, 500)
    },
    language: {
        default: 'fr',
        translations: {
            fr: {
                consentModal: {
                    title: '🍪 Les cookies, ça vous dit quelque chose ?',
                    description: `Chez Réassurez-moi, nous utilisons des cookies pour améliorer votre expérience
                            et mieux répondre à vos besoins. Nous respectons votre vie privée et nous nous engageons à
                            utiliser vos données de manière responsable. Vous pouvez les accepter ou les
                            paramétrer, <button type="button" data-cc="show-preferencesModal" class="cc-link" style="text-decoration: underline; font-weight: bold;">c'est vous qui
                            voyez</button> !`,
                    acceptAllBtn: 'Accepter 👍',
                },
                preferencesModal: {
                    title: 'Voilà nos cookies !',
                    savePreferencesBtn: 'Terminer',
                    acceptAllBtn: 'J\'accepte tout',
                    closeIconLabel: 'Retour',
                    sections: [
                        {
                            title: 'Notre utilisation des cookies 📢',
                            description: `Sur ce site, nous utilisons des cookies pour vous faciliter la vie
                                    ainsi que pour mesurer notre audience, améliorer votre expérience et personnaliser
                                    des annonces. Vous pouvez sélectionner ici ceux que vous autorisez et ceux que vous
                                    refusez.`,
                        },
                        {
                            title: 'Cookies nécessaires',
                            description: `Ces cookies sont indispensables au bon fonctionnement de notre site
                                    internet. Sans ces cookies, le site Web ne fonctionnerait pas correctement`,
                            linkedCategory: COOKIE_CATEGORY_NECESSARY,
                        },
                        {
                            title: 'Analyse et performances',
                            description: `Ces cookies nous permettent de mesurer notre audience et de nous
                                    rendre compte des éventuelles lenteurs ou problèmes de performances`,
                            linkedCategory: COOKIE_CATEGORY_ANALYTICS,
                            cookieTable: {
                                headers: {
                                    "domain": "Service",
                                    "description": "Description",
                                },
                                body: [
                                    {
                                        domain: 'Google Analytics',
                                        description: 'Mesurer notre audience',
                                    },
                                    {
                                        domain: 'Google Tag Manager',
                                        description: 'Mesurer notre conversion pour améliorer l\'expérience utilisateur',
                                    },
                                    {
                                        domain: 'Google Optimize',
                                        description: 'Expérimenter de nouvelles versions plus agréables et performantes de nos page',
                                    },
                                ]},
                        },
                        {
                            title: 'Personnalisation des annonces',
                            description: 'Ces cookies nous permettent de personnaliser nos annonces pour mieux cibler nos campagnes publicitaires',
                            linkedCategory: COOKIE_CATEGORY_ADS,
                            cookieTable: {
                                headers: {
                                    "domain": 'Service',
                                    "description": "Description",
                                },
                                body: [
                                    {
                                        domain: 'Google Ads',
                                        description: 'Personnaliser nos annonces et mesurer leur impact',
                                    }
                                ]
                            },
                        },
                        {
                            title: 'Plus d\'infos',
                            description: `Consultez notre <a href="/guide/politique-confidentialite">
                                    politique de confidentialité</a> pour en savoir plus sur notre utilisation des
                                    cookies`,
                        },
                    ],
                },
            },
        },
    },
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'middle center',
        },
        preferencesModal: {
            layout: 'box',
        },
    },
}

const getCookieRunData = () => {
    window.dataLayer = window.dataLayer || []
    window.consentBlockedEvents = window.consentBlockedEvents || []
    window.adsbygoogle = window.adsbygoogle || []
    return cookieRunData
}

export default getCookieRunData
